import React, { useState, useEffect } from "react"

export const AppContext = React.createContext([{}, () => {}])

export const AppProvider = props => {
  const isBrowser = typeof window !== "undefined"

  const [cart, setCart] = useState(null)

  useEffect(() => {
    if (isBrowser) {
      let cartData = localStorage.getItem("woo-next-cart")
      cartData = null !== cartData ? JSON.parse(cartData) : ""
      setCart(cartData)
    }
  }, [isBrowser])

  return (
    <AppContext.Provider value={[cart, setCart]}>
      {props.children}
    </AppContext.Provider>
  )
}
