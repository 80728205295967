// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-czas-i-koszty-dostawy-js": () => import("./../../../src/pages/czas-i-koszty-dostawy.js" /* webpackChunkName: "component---src-pages-czas-i-koszty-dostawy-js" */),
  "component---src-pages-czas-realizacji-zamowienia-js": () => import("./../../../src/pages/czas-realizacji-zamowienia.js" /* webpackChunkName: "component---src-pages-czas-realizacji-zamowienia-js" */),
  "component---src-pages-formy-platnosci-js": () => import("./../../../src/pages/formy-platnosci.js" /* webpackChunkName: "component---src-pages-formy-platnosci-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-kontakt-potwierdzenie-js": () => import("./../../../src/pages/kontakt-potwierdzenie.js" /* webpackChunkName: "component---src-pages-kontakt-potwierdzenie-js" */),
  "component---src-pages-koszyk-js": () => import("./../../../src/pages/koszyk.js" /* webpackChunkName: "component---src-pages-koszyk-js" */),
  "component---src-pages-logowanie-js": () => import("./../../../src/pages/logowanie.js" /* webpackChunkName: "component---src-pages-logowanie-js" */),
  "component---src-pages-moje-konto-js": () => import("./../../../src/pages/moje-konto.js" /* webpackChunkName: "component---src-pages-moje-konto-js" */),
  "component---src-pages-moje-konto-zamowienie-js": () => import("./../../../src/pages/moje-konto/zamowienie.js" /* webpackChunkName: "component---src-pages-moje-konto-zamowienie-js" */),
  "component---src-pages-o-firmie-js": () => import("./../../../src/pages/o-firmie.js" /* webpackChunkName: "component---src-pages-o-firmie-js" */),
  "component---src-pages-podsumowanie-js": () => import("./../../../src/pages/podsumowanie.js" /* webpackChunkName: "component---src-pages-podsumowanie-js" */),
  "component---src-pages-polityka-cookies-js": () => import("./../../../src/pages/polityka-cookies.js" /* webpackChunkName: "component---src-pages-polityka-cookies-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-porownywarka-js": () => import("./../../../src/pages/porownywarka.js" /* webpackChunkName: "component---src-pages-porownywarka-js" */),
  "component---src-pages-potwierdzenie-js": () => import("./../../../src/pages/potwierdzenie.js" /* webpackChunkName: "component---src-pages-potwierdzenie-js" */),
  "component---src-pages-produkty-js": () => import("./../../../src/pages/produkty.js" /* webpackChunkName: "component---src-pages-produkty-js" */),
  "component---src-pages-produkty-prywatne-js": () => import("./../../../src/pages/produkty-prywatne.js" /* webpackChunkName: "component---src-pages-produkty-prywatne-js" */),
  "component---src-pages-regulamin-js": () => import("./../../../src/pages/regulamin.js" /* webpackChunkName: "component---src-pages-regulamin-js" */),
  "component---src-pages-rejestracja-js": () => import("./../../../src/pages/rejestracja.js" /* webpackChunkName: "component---src-pages-rejestracja-js" */),
  "component---src-pages-resetowanie-hasla-js": () => import("./../../../src/pages/resetowanie-hasla.js" /* webpackChunkName: "component---src-pages-resetowanie-hasla-js" */),
  "component---src-pages-serwis-js": () => import("./../../../src/pages/serwis.js" /* webpackChunkName: "component---src-pages-serwis-js" */),
  "component---src-pages-ulubione-js": () => import("./../../../src/pages/ulubione.js" /* webpackChunkName: "component---src-pages-ulubione-js" */),
  "component---src-pages-zakupy-hurtowe-js": () => import("./../../../src/pages/zakupy-hurtowe.js" /* webpackChunkName: "component---src-pages-zakupy-hurtowe-js" */),
  "component---src-pages-zamowienie-js": () => import("./../../../src/pages/zamowienie.js" /* webpackChunkName: "component---src-pages-zamowienie-js" */),
  "component---src-pages-zwroty-i-reklamacje-js": () => import("./../../../src/pages/zwroty-i-reklamacje.js" /* webpackChunkName: "component---src-pages-zwroty-i-reklamacje-js" */),
  "component---src-templates-archive-js": () => import("./../../../src/templates/archive.js" /* webpackChunkName: "component---src-templates-archive-js" */),
  "component---src-templates-product-js": () => import("./../../../src/templates/product.js" /* webpackChunkName: "component---src-templates-product-js" */),
  "component---src-templates-product-private-js": () => import("./../../../src/templates/product-private.js" /* webpackChunkName: "component---src-templates-product-private-js" */)
}

